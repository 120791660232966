import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Button,
  FormErrorMessage,
  useBreakpointValue,
  Text,
  Flex,
  Heading,
  Textarea,
} from '@chakra-ui/react';
import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IToastType } from '@/enums/ToastType';
import { useStores } from '@/hooks/useStores';
import { useToast } from '@/hooks/useToast';

interface ILibrarianProps {
  name: string;
  email: string;
  message: string;
  institution: string;
  institutionalRole: string;
  informationTopics: Array<string>;
}

interface ILibrariansContactFormProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LibrariansContactForm: FC<ILibrariansContactFormProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { store } = useStores();
  const toast = useToast();
  const isCentered = useBreakpointValue({ base: false, md: true });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm<ILibrarianProps>();

  const onSubmit = useCallback(async (formData) => {
    try {
      await store.request(`contact`, 'POST', {
        data: {
          type: 'contact',
          attributes: {
            type: 'librarian',
            ...formData,
          },
        },
      });

      toast({ status: IToastType.SUCCESS, title: t('librarians.contactForm.successfulSubmission') });
    } catch (e) {
      toast({ status: IToastType.ERROR, title: t('librarians.contactForm.errorSubmit') });
    } finally {
      reset();
      onClose();
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered={isCentered}>
      <ModalOverlay />
      <ModalContent maxW="auto" px={{ base: 0, lg: 4 }} py={6}>
        <ModalCloseButton fontSize={{ base: 'md', lg: 'xl' }} m={0} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Flex maxW={{ base: '400px', lg: '900px' }} w="full" flexDirection={{ base: 'column', lg: 'row' }} gap={4}>
              <Flex gap={4} flexDirection="column" flex={1}>
                <Heading fontSize="xl">Contact us and get a price quote or a 7-day trial!</Heading>
                <Text>
                  We are currently offering subscription to institutions, companies and individuals. Please fill the
                  form bellow and our sales team will contact you.
                </Text>
                <Flex flexDir="column" gap={8} mt={10}>
                  <FormControl variant="floating" isInvalid={Boolean(errors.name)}>
                    <Input
                      placeholder=" "
                      {...register('name', { required: true })}
                      variant="flushed"
                      errorBorderColor="none"
                    />
                    <FormLabel>{t('librarians.contactForm.name.label')}*</FormLabel>
                    <FormErrorMessage>{t('librarians.contactForm.name.required')}</FormErrorMessage>
                  </FormControl>
                  <FormControl variant="floating" isInvalid={Boolean(errors.email)}>
                    <Input
                      placeholder=" "
                      {...register('email', {
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      })}
                      errorBorderColor="none"
                      variant="flushed"
                    />
                    <FormLabel>{t('librarians.contactForm.email.label')}*</FormLabel>
                    <FormErrorMessage>{t(`librarians.contactForm.email.${errors?.email?.type}`)}</FormErrorMessage>
                  </FormControl>
                  <FormControl variant="floating" isInvalid={Boolean(errors.institution)}>
                    <Input placeholder=" " {...register('institution')} variant="flushed" errorBorderColor="none" />
                    <FormLabel>{t('librarians.contactForm.institution.label')}</FormLabel>
                    <FormErrorMessage>{t('librarians.contactForm.institution.required')}</FormErrorMessage>
                  </FormControl>
                </Flex>
              </Flex>
              <Flex justifyContent="flex-end" mt={{ base: 10, lg: 0 }} flexDirection="column" flex={1}>
                <Textarea h="300px" {...register('message')} placeholder="Write a message" />
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter px={4} mt={4} py={0} justifyContent="flex-end">
            <Button size="md" isLoading={isSubmitting} type="submit">
              {t('librarians.contactForm.submit')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
